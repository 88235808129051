<template>
  <loading v-if="isLoading" />
  <div class="login-setting" v-else>
    <validation-observer ref="socialLoginSettingform" class="w-100">
      <b-form @submit.prevent>
        <b-row class="mx-1">
          <b-col md="12" class="mb-1">
            <h2>
              {{ $t("g.platformInfo") }}
            </h2>
          </b-col>
          <!-- driver name  -->
          <b-col
            cols="12"
            md="6"
            class="mb-1"
            v-for="platForm in platForms"
            :key="platForm.id"
          >
            <b-form-group
              :label="$t(`g.${platForm.name}`)"
              :label-for="platForm.key"
            >
              <validation-provider
                :rules="platForm.rules"
                #default="{ errors }"
                :name="platForm.key"
              >
                <b-form-input
                  :disabled="!canEdit"
                  :type="platForm.type && platForm.type"
                  :state="errors.length > 0 ? false : null"
                  v-model="platForm.value"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col class="actions" cols="6" sm="12">
            <b-overlay
              :show="busyButton"
              rounded
              opacity="0.7"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
            >
              <b-button
                v-if="canEdit"
                variant="primary"
                class="px-3"
                type="submit"
                @click="submitForm"
              >
                {{ $t("g.settings/generalSettings/submitButton") }}
              </b-button>
            </b-overlay>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormFile,
  BRow,
  BButton,
  BOverlay,
  BLink,
} from "bootstrap-vue";
import { required, max, min } from "@validations";
import loading from "@/components/shared/loading/loading.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import OverlayComponent from "@/components/shared/OverlayComponent/index.vue";
export default {
  name: "Settings",
  components: {
    BCol,
    ValidationObserver,
    ValidationProvider,
    BFormGroup,
    BFormInput,
    BForm,
    BFormFile,
    BRow,
    BButton,
    BLink,
    BOverlay,
    OverlayComponent,
    loading,
  },
  data() {
    return {
      platForms: [
        {
          id: 1,
          name: "Phone",
          value: null,
          rules: "max:9|min:9",
          key: "site_phone",
          type: "number",
        },
        {
          id: 2,
          name: "WhatsApp",
          value: null,
          rules: "",
          key: "whatsapp",
          type: "text",
        },
        {
          id: 3,
          rules: "",
          name: "Twitter",
          value: null,
          key: "twitter",
          type: "text",
        },
        {
          id: 4,
          rules: "",
          name: "FaceBook",
          value: null,
          key: "facebook",
          type: "text",
        },
        {
          id: 5,
          name: "Instagram",
          value: null,
          rules: "",
          key: "instagram",
          type: "text",
        },
        {
          id: 6,
          rules: "",
          name: "Youtube",
          value: null,
          key: "youtube",
          type: "text",
        },
        {
          id: 7,
          rules: "",
          name: "Linkedin",
          value: null,
          key: "linkedin",
          type: "text",
        },
        {
          id: 8,
          rules: "",
          name: "Snapchat",
          value: null,
          key: "snapchat",
          type: "text",
        },
        {
          id: 9,
          rules: "",
          name: "Github",
          value: null,
          key: "github",
          type: "text",
        },
        {
          id: 10,
          rules: "",
          name: "Apple",
          value: null,
          key: "apple",
          type: "text",
        },
        {
          id: 11,
          name: "Email",
          rules: "",
          value: null,
          key: "site_email",
          type: "email",
        },
        {
          id: 12,
          name: "Website link",
          rules: "",
          value: null,
          key: "site_link",
          type: "text",
        },
        {
          id: 13,
          name: "Latitude",
          value: null,
          rules: "",
          key: "latitude",
          type: "number",
        },
        {
          id: 14,
          name: "Longitude",
          value: null,
          rules: "",
          key: "longitude",
          type: "number",
        },
        {
          id: 15,
          name: "The address is in Arabic",
          value: null,
          rules: "",
          key: "site_address_ar",
          type: "text",
        },
        {
          id: 16,
          name: "The address is in English",
          rules: "",
          value: null,
          key: "site_address_en",
          type: "text",
        },
        {
          id: 17,
          name: "App Store download link",
          value: null,
          rules: "",
          key: "app_store",
          type: "text",
        },
        {
          id: 18,
          name: "Play Store download link",
          value: null,
          rules: "",
          key: "play_store",
          type: "text",
        },
      ],
      archive: {},
      isLoading: false,
      busyButton: false,
    };
  },
  created() {
    this.getMyData();
  },
  computed: {
    canEdit() {
      return (
        this.globalPermission.settings && this.globalPermission.settings.update
      );
    },
  },
  methods: {
    async getMyData() {
      this.isLoading = true;
      try {
        const response = await this.$http.get("admin/settings");

        if (response.status == 200) {
          const wantedKeys = [
            "site_phone",
            "whatsapp",
            "facebook",
            "github",
            "apple",
            "linkedin",
            "instagram",
            "twitter",
            "youtube",
            "snapchat",
            "site_email",
            "site_link",
            "site_address_ar",
            "site_address_en",
            "app_store",
            "play_store",
            "longitude",
            "latitude",
          ];
          response.data.data.map((ele) => {
            if (wantedKeys.includes(ele.key)) {
              this.archive[ele.key] = ele.value?.value;
              this.platForms = this.platForms.map((item) => {
                if (item.key == ele.key) {
                  if (item.key === "site_phone") {
                    return { ...item, value: ele.value?.phone };
                  }
                  return { ...item, value: ele.value?.value };
                } else {
                  return item;
                }
              });
            }
          });
        }
      } catch (errors) {
        this.isLoading = false;
        this.$helpers.handleError(errors);
      } finally {
        this.isLoading = false;
      }
    },
    async submitForm() {
      try {
        this.busyButton = true;
        const validation = await this.$refs.socialLoginSettingform.validate();
        if (validation) {
          const toEditArray = [];
          this.platForms.map(({ key, value }) => {
            if (value != this.archive[key]) {
              toEditArray.push({ key, value });
            }
          });
          toEditArray.map(async ({ key, value }) => {
            const formData = new FormData();
            formData.append("value", value);
            const res = await this.$http.post(
              `admin/settings/${key}?_method=put`,
              formData
            );
          });
          this.$helpers.makeToast(
            "success",
            "success",
            "platform_information_updated"
          );
        }
      } catch (err) {
        this.busyButton = false;
        this.$helpers.handleError(err);
      } finally {
        this.busyButton = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./_index.scss";
</style>
